import React, { useState } from "react";
import classes from "./user.module.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { SortIcon } from "../../Assets/Icons/icons"; // Ensure this is used where needed
import UserTableRows from "./userTableRows";
import { useSelector } from "react-redux";

const UserTable = ({ checkedUsers, setCheckedUsers, dynamicHeaders }) => {
  const { customersList } = useSelector((state) => state.customerData); // Get customer list from Redux state
  // Handle checkbox selection for users
  // Handle checkbox selection for users
  const handleCheckUsers = (e, datas) => {
    const { checked } = e.target;
    if (checked) {
      setCheckedUsers((prevState) => [...prevState, datas.id]); // Add user ID to selected
    } else {
      setCheckedUsers((prevState) => prevState.filter((id) => id !== datas.id)); // Remove user ID from selected
    }
  };

  return (
    <Table>
      {/* Table Header */}
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 72 }}></TableCell>{" "}
          {/* Checkbox column */}
          {dynamicHeaders?.map((header, index) => (
            <TableCell key={index} style={{ minWidth: 125 }}>
              <span>{header}</span>
              {/* Add SortIcon or other functionality here if required */}
            </TableCell>
          ))}
          <TableCell style={{ minWidth: 125 }}>
            <span>Action</span>
          </TableCell>
        </TableRow>
      </TableHead>

      {/* Table Body */}
      <TableBody>
        {customersList?.map((customer, index) => (
          <UserTableRows
            key={index}
            item={customer} 
            dynamicHeaders={dynamicHeaders} 
            handleCheckUsers={handleCheckUsers} 
            checkedUsers={checkedUsers} 
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default UserTable;
