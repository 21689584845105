import axios from "axios";
import { api, authApi, getAuthorizationHeader } from "../../Api/api";

export const GetAllLocation = async () => {
  try {
    const { data } = await authApi.get("/property/getall-locations", {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    return data.data    ;
  } catch (error) {
    console.log(error);
  }
};
export const GetCompareData = async (body,location,locality) => {
    try {
      const { data } = await authApi.post(`/property/get-compare-data?location=${location?locality:""}&locality=${locality ? locality : ""}`,body, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return data.data    ;
    } catch (error) {
      console.log(error);
    }
  };

 export const  formatDate=(date)=> {
    const d = new Date(date); // Parse the input date string
    const monthText = d.toLocaleString('default', { month: 'long' }); // Get the full month name
    const monthNumber = d.getMonth() + 1; // Get the month number (0-11, so add 1)
    const year = d.getFullYear(); // Get the year

    return {
        year: year,
        month_in_letter: monthText,
        month_in_number: monthNumber
    };
}