import {
  AggrecutureLandIcon,
  CommercialBuildingIcon,
  LandsPlotsIcon,
  ResidentialHomeIcon,
} from "../Assets/Icons/icons";
import moment from "moment";

export const PropertyPostingList3 = [
  {
    text: "Plots/lands",
    value: "661cc347267fb2f57d21fb9a",
    icon: <LandsPlotsIcon />,
  },
  {
    text: "Residential",
    value: "661cde3d267fb2f57d2203e2",
    icon: <ResidentialHomeIcon />,
  },
  {
    text: "Commercial",
    value: "661cc37f267fb2f57d21fb9d",
    icon: <CommercialBuildingIcon />,
  },
  {
    text: "Agricultural/\nFarmlands",
    value: "661cc398267fb2f57d21fba0",
    icon: <AggrecutureLandIcon />,
  },
  {
    text: "New Projects",
    value: "662f64cf68e54862587dffe0",
    icon: <AggrecutureLandIcon />,
  },
];

export const UserTypeList = [
  { text: "Owner", value: "owner" },
  { text: "Agent", value: "agent" },
];

export const LookingToList = [
  { text: "Sell", value: "sell" },
  { text: "Rent/Lease", value: "rent_lease" },
];

export const PropTypeList = [
  { text: "Residential Apartments", value: "residential_apartments" },
  { text: "House/Villa", value: "house_villa" },
  { text: "Independent Houses", value: "independent_houses" },
  { text: "Other", value: "other" },
];

export const bhkTypeList = [
  { text: "1RK", value: "1rk" },
  { text: "1BHK", value: "1bhk" },
  { text: "2BHK", value: "2bhk" },
  { text: "3BHK", value: "3bhk" },
  { text: "4BHk+", value: "4bhk+" },
  { text: "5+BHK", value: "5+bhk" },
];

export const noOfBathroomsList = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

export const furnishedList = [
  { text: "furnished", value: "furnished" },
  { text: "Unfurnished", value: "unfurnished" },
  { text: "semi-furnished", value: "semi_furnished" },
];

export const constructionTypeList = [
  { text: "Under construction", value: "under_construction" },
  { text: "ready to move", value: "ready_to_move" },
];

export const range = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
  "Last Year": [
    moment().subtract(1, "year").startOf("year"),
    moment().subtract(1, "year").endOf("year"),
  ],
};


export const  country_codes = [
  "1", "1242", "1246", "1264", "1268", "1345", "1441", "1473", "1649", "1664", 
  "1758", "1787", "1809", "1868", "1869", "1876", "20", "212", "213", "216", 
  "218", "220", "221", "222", "223", "224", "226", "227", "228", "229", "231", 
  "232", "233", "234", "236", "237", "238", "239", "240", "241", "242", "244", 
  "245", "248", "249", "250", "251", "252", "253", "254", "256", "257", "258", 
  "260", "261", "262", "263", "264", "265", "266", "267", "268", "269", "27", 
  "290", "291", "297", "298", "299", "30", "31", "32", "33", "34", "350", 
  "351", "352", "353", "354", "356", "357", "358", "359", "36", "370", "371", 
  "372", "373", "374", "375", "376", "377", "378", "379", "380", "381", "385", 
  "386", "387", "389", "39", "40", "41", "417", "42", "421", "43", "44", "45", 
  "46", "47", "48", "49", "500", "501", "502", "503", "504", "505", "506", 
  "507", "509", "51", "52", "53", "54", "55", "56", "57", "58", "590", "591", 
  "592", "593", "594", "595", "596", "597", "598", "60", "61", "62", "63", 
  "64", "65", "66", "670", "671", "672", "673", "674", "675", "676", "677", 
  "678", "679", "680", "681", "682", "683", "686", "687", "688", "689", "691", 
  "692", "7", "7880", "81", "82", "84", "850", "852", "853", "855", "856", 
  "86", "880", "886", "90", "90392", "91", "94", "95", "960", "961", "962", 
  "963", "964", "965", "966", "967", "968", "969", "971", "972", "973", "974", 
  "975", "976", "977", "98", "993", "994", "996"
];
