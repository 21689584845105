import React from "react";
import { TableRow, TableCell, Checkbox, Button } from "@mui/material";
import { MdCheckCircle, MdOutlineRadioButtonUnchecked } from "react-icons/md";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { EditPenIcon, DeleteBinIcon } from "../../Assets/Icons/icons";

const UserTableRows = ({ item, dynamicHeaders, handleCheckUsers, checkedUsers }) => {
  const navigate = useNavigate();
  console.log(item);

  return (
    <TableRow>
      <TableCell style={{ minWidth: 72 }}>
        <Checkbox
          icon={<MdOutlineRadioButtonUnchecked />}
          checkedIcon={<MdCheckCircle />}
          checked={checkedUsers?.includes(item.id)}
          onChange={(e) => handleCheckUsers(e, item)}
        />
      </TableCell>
      {dynamicHeaders?.map((header, index) => {
        let data
        if (header === "User ID") {
          data = item[header].substring(0, 6);
        } else {
          data = item[header];
        }

        return (
          <TableCell key={index} style={{ minWidth: 125 }}>
            {data ? data : "-"}
          </TableCell>
        );
      })}
      <TableCell style={{ minWidth: 125 }}>
        <div className="d-flex  align-items-center gap-2"  > 
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#093f41",
              ":hover": {
                backgroundColor: "#093f41",

              }
            }}
            onClick={() => navigate(`/update-user/${item["User ID"]}`)}
          >
            <EditPenIcon />
            <span>Edit</span>
          </Button>
          <Button variant="contained" onClick={() => console.log("Delete user")} sx={{
            backgroundColor: "#093f41",
            ":hover": {
              backgroundColor: "#093f41", // If you want no color change, you can specify this.
            },
          }}>
            <DeleteBinIcon />
            <span>Delete</span>
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default UserTableRows;
