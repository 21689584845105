import React, { useState } from "react";
import { LinearProgress, Typography, Box } from "@mui/material";

// LinearProgressWithLabel component
export function LinearProgressWithLabel({max, value, color }) {
  const [presentValue, setPresentValue] = useState(0);
  setTimeout(() => {
    setPresentValue(value);
  }, 100);
  return (
    <Box display="flex" alignItems="center">
    <Box width="100%" mr={1}>
      <LinearProgress
        variant="determinate"
        value={presentValue} // Ensure presentValue is between 0 and 20
        maxValue={20} // Maximum value for the progress bar
        sx={{
          height: "8px", // Change height of the progress bar
          borderRadius: "10px", // Rounded corners
          backgroundColor: "#F4F4F5", // Track color (the background)
          "& .MuiLinearProgress-bar": {
            borderRadius: "10px",
            backgroundColor: color, // Progress color (the filled part)
            transition: "transform 2s ease-out", // Smooth transition for the filled part
          },
        }}
      />
    </Box>
  </Box>
  );
}
